// Colors
$black: #000000;
$dark-gray: #292929;
$gray-navy: #47506C;
$navy-blue: #07255B;
$busright-blue: #131387;
$bright-blue: #2270E8;
$medium-blue: #B2D0FD;
$light-blue: #E1E9EF;
$background-blue: rgba(178,208,253,0.15);
$yellow:#F9C33D;
$white:  #FFFFFF;
$red: #E0604F;
$green: #89C5B8;
$blue: #2F5AAA;

// Fonts
$Lato: 'Lato', sans-serif;
$Nunito: 'Nunito', sans-serif;

// Media Queries (old) TODO: remove usage (after all pages overhauled)
$tablet-width: 768px;
$mobile-width: 480px;

// Media Queries
$screen-width-xl: 1200px;
$screen-width-lg: 992px;
$screen-width-md: 768px;
$screen-width-sm: 576px;

// Section Heights
$hero-height-desktop: 668px;
$hero-height-tablet: 668px;
$hero-height-mobile: 668px;

// Margins
$site-margin-large: 100px;
$site-margin-medium: 50px;
$site-margin-small: 40px;
$site-margin-tiny: 30px;


//Container Widths
$container-width-xl: 1170px;
$container-width-lg: 970px;
$container-width-md: 750px;
$container-width-sm: 540px;
$container-width-xs: auto;

//Nav
$nav-height: 60px;

$button-radius: 28px;

.container {
  padding-right: 40px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
  max-width: $container-width-xs;

  @media screen and (min-width: $screen-width-sm) {
    max-width: $container-width-sm;
  }
  @media screen and (min-width: $screen-width-md) {
    max-width: $container-width-md;
  }
  @media screen and (min-width: $screen-width-lg) {
    max-width: $container-width-lg;
  }
  @media screen and (min-width: $screen-width-xl) {
    max-width: $container-width-xl;
  }
}

.container section .fade {
  visibility: visible;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  -webkit-transition: opacity 0.5s 0.1s, -webkit-transform 0.5s 0.1s;
  transition: opacity 0.5s 0.1s, -webkit-transform 0.5s 0.1s;
  transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
  transition: transform 0.5s 0.1s, opacity 0.5s 0.1s, -webkit-transform 0.5s 0.1s;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

a {
  text-decoration: none;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.button {
  display: inline-flex;
  background-color: $yellow;
  border-radius: $button-radius;
  padding: 10px 30px;
  color: $navy-blue;
  font-weight: bold;

  font-family: $Nunito;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
  margin: 1em 0;

  border: 0;
  cursor: pointer;


  &:hover {
    background-color: color($yellow shade(10%));
  }

  &.button-cta {
    padding: 15px 30px;
    border-radius: 36px;
  }

  &.button-white {
    background-color: $white;

    &:hover {
      background-color: color($white shade(10%));
    }
  }

  &.button-red, &.button-green, &.button-blue {
    color: $white;
  }

  &.button-red {
    background-color: $red;

    &:hover {
      background-color: color($red shade(10%));
    }
  }

  &.button-green {
    background-color: $green;

    &:hover {
      background-color: color($green shade(10%));
    }
  }

  &.button-blue {
    background-color: $blue;

    &:hover {
      background-color: color($blue shade(10%));
    }
  }
}

h1 {
  font-size: 30px;
  font-family: $Lato;
  font-style: normal;
  font-weight: bold;
  margin: .5em 0;
  line-height: 120%;

  &.black {
    font-weight: 900;
  }

  @media screen and (min-width: $screen-width-lg) {
    font-size: 35px;
  }
}

h2 {
  font-family: $Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin: .2em 0 .5em 0;
  line-height: 120%;

  @media screen and (min-width: $screen-width-lg) {
    font-size: 28px;
  }
}

h4 {
  font-family: $Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: .2em 0;

  &.bold {
    font-weight: bold;
  }

  @media screen and (min-width: $screen-width-lg) {
    font-size: 20px;
  }
}

h6 {
  font-family: $Nunito;
  font-weight: normal;
  font-size: 16px;
  margin: .2em 0;

  @media screen and (min-width: $screen-width-lg) {
    font-size: 18px;
  }
}

input[type="text"] {
  flex: 1;
  padding: 0px;
  padding-right: 50px;
  padding-left: 25px;
  border-radius: $button-radius;
  height: 45px;
  border: 0px;
  font-size: 14px;
  font-family: $Lato;
  outline: none;
}

.pad-tp-lg {
  padding-top: 6em;
}

.pad-tp-md {
  padding-top: 4em;
}

.pad-tp-sm {
  padding-top: 2em;
}

.pad-tp-xs {
  padding-top: 1em;
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  width: 100%;

  > div {
    margin: auto;
    float: left;
    grid-column: span 12;
    -ms-grid-column-span: 12;
    -ms-grid-column: 1;

    &.hero-img img, .feature-img {
      max-width: $container-width-sm;
      width: 80vw;

      @media screen and (min-width: $screen-width-lg) {
        width: 100%;
        max-width: calc($container-width-xl * .6);
      }
    }

    .app-icons {
      a {

        img {
          width: 48%;
        }

        &:nth-of-type(2) {
          margin-left: 2%;
        }
      }
    }

    @media screen and (max-width: $screen-width-lg - 1) {
      -ms-grid-row: 1;

      &:nth-child(2) {
        -ms-grid-row: 2;
      }
    }
  }

  .col-lg-12 {
    -ms-grid-row: 1;

    &:nth-child(2) {
      -ms-grid-row: 2;
    }
  }

  @media screen and (min-width: $screen-width-lg) {
    .col-lg-6 {
        grid-column: span 6;
        -ms-grid-column-span: 6;
        -ms-grid-column: 1;

        &:nth-child(2) {
          -ms-grid-column: 7;
        }
    }
  }

  &.row-rev-lg {
    @media screen and (min-width: $screen-width-lg) {
      > :first-child {
        order: 2;
        -ms-grid-column: 7;
      }

      > :nth-child(2) {
        -ms-grid-column: 1;
      }
    }
  }
}

img.bg-svg {
  position: absolute;
  z-index: -1 !important;
}