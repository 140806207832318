
.navbar-master {
  background-color: $navy-blue;
}


.navbar-wrapper {
  min-height: $nav-height;
  width: 100%;
  z-index: 100;
  position: absolute;

  padding-top: 1em;
  padding-bottom: 1em;

  @media screen and (min-width: $screen-width-lg) {
    padding-top: 2em;  
  }

  &.fixed {
    padding-top: 1em;
    padding-bottom: 1em;

    position: fixed;
    top: 0;  
    transition: top 0.3s; /* Transition effect when sliding down (and up) */  

    &.hidden {
      top: calc(-1 * ($nav-height + 2em));
    }
  }
}

.navbar .navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $nav-height;
}

.navbar-logo {
  display: inherit;

  a {
    margin: auto;
    margin-top: 0.4em;
    margin-right: 20px;


    @media screen and (min-width: $screen-width-xl) {
      margin-right: 40px;
    }

    @media screen and (min-width: $screen-width-lg) {
      margin-top: 1.5em;
    }
  }
  
  img {
    width: 150px;
  }
}

.navlinks {
  display: none;

  @media screen and (min-width: $screen-width-lg) {
    display: flex;
  }
  
  a {
    text-decoration: none;
    color: $yellow;
    line-height: 70px;

    cursor: pointer;

    h6:hover {
      color: $white;
    }
  }
}


.navbar-navlinks-left, .navbar-navlinks-right{
  margin: 0 0 0 0;

    a {
    margin: auto;
    margin-right: 40px;

    @media screen and (max-width: $screen-width-xl) {
      margin-right: 20px;
    }
  }

  a:last-child {
    margin-right: 0px;
  }
}

.navbar-navlinks-right {
  a h6 {
    font-weight: bold;
  }
}



/* MOBILE NAV */

.navbar a.icon {
  color: $white;
  display: block;
  right: 0;
  top: 0;
  text-decoration: none;
  font-size: 40px;
  font-family: $Nunito;
  font-weight: bold;
  display: block;
}


@media screen and (min-width: $screen-width-lg) {
  .navbar a.icon, #mobileLinks {
    display: none;
  }
}

.mobile-container {
  display: none;
  width: 100%;
  margin: auto;
}

#mobileLinks {
  text-align: center;
  padding-top: 2em;

  h6, .button {
    font-size: 1.5rem;
  }

  .button {
    margin: 0em;
  }

  a {
    line-height: 60px;
  }

  hr {
    height: 20px;
    border: 0px;
  }
}

.topnav {
  width: 100%;
  overflow: hidden;
  box-shadow: 1px 2px 2px 0 #E7F0FF;
  position: relative;
  align-items: center;
}

.topnav .nav-header {
  border-bottom: 2px solid rgba(255, 255, 255, .3);
}

.active {
  color: $white !important;
}