.user-features {
  height: 100%;
  margin: 32px 0 10px 0;
  overflow: hidden;
}

.user-features-maintext {
  color: $busright-blue;
  font-family: $Nunito;
  font-size: 40px;
  font-weight: bold;
  width: 600px;
  margin: 0 0 0 0px;
}

.user-features-subtext {
  color: $navy-blue;
  font-family: $Lato;
  font-size: 22px;
  width: 340px;
  margin: 15px 0 0 0px;
}

.user-features-section {
  display: flex;
  flex-direction: column;

  width: 380px;
  margin: 100px 0 0 100px;

  &.navigate-routes {
    margin: 100px 250px 0 0;
  }

  &.manage-busses {
    margin: 100px 0 0 100px;
  }
}

.admins-flower-1 {
  position: absolute;
  height: 190px;
  width: 190px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;
  z-index: -20;

  top: 800px;
  right: 200px;
}

.admins-flower-2 {
  position: absolute;
  height: 190px;
  width: 190px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;
  z-index: -20;

  top: 1487px;
  right: 50px;
}

.admins-flower-3 {
  position: absolute;
  height: 190px;
  width: 190px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;
  z-index: -20;

  top: 1867px;
  left: -85px;
}

.admins-flower-4 {
  position: absolute;
  height: 190px;
  width: 190px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;
  z-index: -20;

  top: 2197px;
  right: 50px;
}

.admins-school-bus {
  position: absolute;
  height: 120px;
  width: 300px;
  background-image: url("../../../img/school-bus.png");
  background-size: contain;

  top: 1450px;
  left: 100px;
  z-index: -10;
}

.admins-trees {
  position: absolute;
  height: 170px;
  width: 600px;
  background-image: url("../../../img/trees-2.png");
  background-size: contain;

  top: 1350px;
  left: 125px;
  z-index: -20;
}

.admins-trees-2 {
  position: absolute;
  height: 165px;
  width: 550px;
  background-image: url("../../../img/trees-2.png");
  background-size: contain;

  transform: scaleX(-1);
  top: 2250px;
  left: 100px;
  z-index: -20;
}

.admins-lock {
  position: absolute;
  height: 176px;
  width: 132px;
  background-image: url("../../../img/lock.png");
  background-size: contain;

  top: 2200px;
  left: 400px;
  z-index: -10;
}

.admin-features-image {
  height: 525px;
  width: auto;
  padding-left: 35%;
}

.parent-features-image {
  height: 400px;
  width: auto;
  padding-left: 35%;
}

.driver-features-image {
  height: 500px;
  width: auto;
  padding-left: 35%;
}


@media screen and (max-width: $tablet-width) {
  .user-features-section {
    margin: 32px 0 0 100px;
  }

  .user-features-maintext {
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 40px;
    font-weight: bold;
    width: 600px;
    margin: 0 0 0 0px;
  }

  .user-features-subtext {
    color: $dark-gray;
    font-family: $Lato;
    font-size: 22px;
    width: 340px;
    margin: 15px 0 0 0px;
  }

  .admins-school-bus {
    position: absolute;
    height: 60px;
    width: 300px;
    background-image: url("../../../img/school-bus.png");
    background-size: contain;
    background-repeat: no-repeat;

    top: 1225px;
    left: 100px;
    z-index: -10;
  }

  .admins-trees {
    position: absolute;
    height: 60px;
    width: 300px;
    background-image: url("../../../img/trees-2.png");
    background-size: contain;

    top: 1200px;
    left: 100px;
    z-index: -20;
  }

  .admins-trees-2 {
    position: absolute;
    height: 100px;
    width: 400px;
    background-image: url("../../../img/trees-2.png");
    background-size: contain;
    background-repeat: no-repeat;

    transform: scaleX(-1);
    top: 2350px;
    left: 50px;
    z-index: -20;
  }

  .admins-lock {
    position: absolute;
    height: 100px;
    width: 75px;
    background-image: url("../../../img/lock.png");
    background-size: contain;
    background-repeat: no-repeat;

    top: 2300px;
    left: 200px;
    z-index: -10;
  }

  .admin-features-image {
    padding-left: 35%;
    margin: 50px 0 25px 0;
    height: 250px;
    width: auto;
  }

  .parent-features-image {
    height: 300px;
    width: auto;
    padding-left: 15%;
  }

  .driver-features-image {
    height: 300px;
    width: auto;
    padding-left: 10%;
  }

}

@media screen and (max-width: $mobile-width) {
  .user-features {
    margin: 10px 0 0 0;
  }

  .user-features-section {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 40px;

    &.navigate-routes {
      margin: 100px 250px 0 0;
    }

    &.manage-busses {
      margin: 100px 0 0 100px;
    }
  }

  .user-features-maintext {
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 20px;
    font-weight: bold;
    width: 300px;
    margin: 0 0 0 0px;
  }

  .user-features-subtext {
    color: $dark-gray;
    font-family: $Lato;
    font-size: 16px;
    width: 300px;
    margin: 15px 0 0 0px;
  }

  .admin-features-image {
    height: 150px;
    width: auto;
    padding-left: 30%;
  }

  .parent-features-image {
    height: 150px;
    width: auto;
    padding-left: 10%;
  }

  .driver-features-image {
    height: 100px;
    width: auto;
    padding-left: 10%;
  }

  .admins-school-bus {
    position: absolute;
    height: 33px;
    width: 84px;
    background-image: url("../../../img/school-bus.png");
    background-size: contain;
    background-repeat: no-repeat;

    top: 750px;
    left: 25px;
    z-index: -10;
  }

  .admins-trees {
    position: absolute;
    height: 65px;
    background-image: url("../../../img/trees-2.png");
    background-size: contain;

    top: 700px;
    left: 50px;
    z-index: -20;
  }

  .admins-trees-2 {
    position: absolute;
    height: 65px;
    background-image: url("../../../img/trees-2.png");
    background-size: contain;
    background-repeat: no-repeat;

    transform: scaleX(-1);
    top: 1500px;
    left: -150px;
    z-index: -20;
  }

  .admins-lock {
    position: absolute;
    height: 55px;
    width: 100%;
    background-image: url("../../../img/lock.png");
    background-size: contain;
    background-repeat: no-repeat;

    top: 1450px;
    left: 100px;
    z-index: -10;
  }

  .admins-flower-1 {
    position: absolute;
    height: 72px;
    width: 72px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;
    z-index: -20;

    top: 436px;
    left: 284px;
  }

  .admins-flower-2 {
    position: absolute;
    height: 72px;
    width: 72px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;
    z-index: -20;

    top: 764px;
    left: 0px;
  }

  .admins-flower-3 {
    position: absolute;
    height: 72px;
    width: 72px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;
    z-index: -20;

    top: 800px;
    right: 0px;
  }

  .admins-flower-4 {
    display: none;
  }

}