.user-download-now {
  width: 100%;
  background-image: url("../../../img/get-started-banner.png");
  background-size: cover;
  padding: 80px 0;
}

.user-download-now-text {
  margin: 36px 0 0 0;
  color: $busright-blue;
  font-family: $Nunito;
  font-size: 40px;
  font-weight: bold;

  margin: 0;
}

.user-download-now-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user-download-now-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 135px;
  background-color: #2270E8;
  border-radius: 2px;
  margin: 28px 8px 0 0;

  p {
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
}

@media screen and (max-width: $tablet-width) {
  .user-download-now {
    padding-top: 44px;
    height: 200px;
    width: 100%;
  }

  .user-download-now-text {
    padding-top: 0px;
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 40px;
    font-weight: bold;
    margin: 0;
  }
}

@media screen and (max-width: $mobile-width) {
  .user-download-now {
    height: 164px;
    padding-top: 0px;
    width: 100%;
    background-image: url("../../../img/get-started-banner.png");
    background-size: cover;
  }

  .user-download-now-text {
    padding-top: 0px;
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

}