#admin-features {

  .main-title {
    .bg-svg {
      top: 0px;
      right: 0px;
    }
  }

  .feature-section {

    #feature-1 {
      .bg-svg {
        top: 40px;
      }
    }

    #feature-2 {
      .bg-svg {
        right: 45%;
      }
    }

    #feature-3 {
      .bg-svg {
        top: -50px;
      }
    }
  }

}
