#homepage-main {
  height: 100%;
  width: 100%;

  position: relative;

  .main-title {
    .bg-svg {
      right: 100px;
      bottom: 0px;
    }

    #home-large-bg {
      top: 0px;
      left: 0px;
      bottom: auto;
      right: auto;
    }
  }
  
  .feature-row {
  
    img {
      z-index: 10;
  
      @media screen and (max-width: $screen-width-lg) {
        margin-bottom: 2em;
      }
  
    }

    .bg-svg {
      bottom: -150px;
    }

    &#feature-1 .bg-svg {
      bottom: -100px;
      right: 150px;
    }

    &#feature-3 .bg-svg {
      right: 45%;
      bottom: -100px;
    }

  
    &#feature-2 .bg-svg  {
        left: -50px;
    }
  }

}