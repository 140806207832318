.our-story-team {
  height: 1800px;
  background-color: $background-blue;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow: hidden;

  margin: 0 0 0 0;
}

.our-story-team-title {
  color: $busright-blue;
  font-family: $Nunito;
  font-size: 50px;
  font-weight: bold;
}

.our-story-team-cards {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-story-team-card {
  display: flex;
  width: 800px;
  height: 293px;
  border-radius: 6px;
  background-color: $white;

  box-shadow: 1px 1px 2px 0 $light-blue;

  margin-bottom: 22px;

  img {
    height: 210px;
    width: 258px;
    object-fit: cover;
    margin: 42px 42px 0 42px;
  }
}

.our-story-team-card-name {
  color: $busright-blue;
  font-family: $Nunito;
  font-size: 30px;
  font-weight: bold;
  margin-top: 42px;
  margin-bottom: 20px;
}


.our-story-team-card-description {
  color: $navy-blue;
  font-family: $Lato;
  font-size: 22px;
  width: 441px;
}

.team-flower-1 {
  position: absolute;
  overflow: hidden;
  height: 189px;
  width: 189px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;

  top: 1047px;
  left: 1111px;
  z-index: -10;
}

.team-flower-2 {
  position: absolute;
  height: 189px;
  width: 189px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;

  top: 1907px;
  left: 151px;
  z-index: -10;
}

.team-flower-3 {
  position: absolute;
  height: 189px;
  width: 189px;
  background-image: url("../../../img/homepage/blue-bg-flower.png");
  background-size: contain;

  top: 2187px;
  left: 910px;
  z-index: -10;
}

@media screen and (max-width: $tablet-width) {
  .our-story-team {
    height: 1800px;
    background-color: $background-blue;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 0 0 0;
  }

  .our-story-team-title {
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 40px;
    font-weight: bold;
  }

  .our-story-team-cards {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .our-story-team-card {
    display: flex;
    width: 580px;
    height: 300px;
    border-radius: 6px;
    background-color: $white;

    box-shadow: 1px 1px 2px 0 $light-blue;

    margin-bottom: 5px;

    img {
      height: 150px;
      width: 188px;
      object-fit: cover;
      margin: 36px 36px 0 36px;
    }
  }

  .our-story-team-card-name {
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 30px;
    font-weight: bold;
    margin-top: 36px;
    margin-bottom: 0px;
  }

  .our-story-team-card-description {
    color: $navy-blue;
    font-family: $Lato;
    font-size: 22px;
    width: 321px;
    margin-top: 0px;
  }

  .team-flower-1 {
    position: absolute;
    height: 120px;
    width: 120px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 1047px;
    left: 1111px;
    z-index: -10;
  }

  .team-flower-2 {
    position: absolute;
    height: 189px;
    width: 189px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 1141px;
    left: -60px;
    z-index: -10;
  }

  .team-flower-3 {
    position: absolute;
    height: 189px;
    width: 189px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 1852px;
    left: 0px;
    z-index: -10;
  }
}

@media screen and (max-width: $mobile-width) {
  .our-story-team {
    height: 2300px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 0 0 0;
  }

  .our-story-team-title {
    font-size: 20px;
    font-weight: bold;
  }

  .our-story-team-cards {
    justify-content: space-between;
    display: block;
    flex-direction: column;
    align-items: center;
  }

  .our-story-team-card {
    display: flex;
    width: 298px;
    height: 413px;
    border-radius: 6px;
    background-color: $white;

    box-shadow: 1px 1px 2px 0 $light-blue;

    margin-bottom: 22px;

    img {
      height: 210px;
      width: 262px;
      object-fit: cover;
      margin: 16px 16px 0 16px;
    }
  }

  .our-story-team-card-text {
    position: absolute;
    margin: 200px 0 0 16px;
  }

  .our-story-team-card-name {
    color: $busright-blue;
    font-family: $Nunito;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }


  .our-story-team-card-description {
    color: $navy-blue;
    font-family: $Lato;
    font-size: 16px;
    width: 275px;
  }

  .team-flower-1 {
    position: absolute;
    height: 189px;
    width: 189px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 1047px;
    left: 1111px;
    z-index: -10;
  }

  .team-flower-2 {
    position: absolute;
    height: 189px;
    width: 189px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 1907px;
    left: 151px;
    z-index: -10;
  }

  .team-flower-3 {
    position: absolute;
    height: 189px;
    width: 189px;
    background-image: url("../../../img/homepage/blue-bg-flower.png");
    background-size: contain;

    top: 2187px;
    left: 910px;
    z-index: -10;
  }
}

