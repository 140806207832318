/*
 The default CSS file.

 You can use @import here to include CSS inside folders
*/

@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,900|Nunito:400,400i,700&display=swap');

@import "imports/reset.css";
@import "../scss/globals.scss";
@import "../scss/partials/navbar.scss";
@import "../scss/partials/infoFooter.scss";
@import "../scss/partials/ctaFooter.scss";
@import "../scss/partials/featureSection.scss";

@import "../scss/partials/userpages/userpages.scss";
@import "../scss/partials/userpages/adminFeatures.scss";
@import "../scss/partials/userpages/parentFeatures.scss";
@import "../scss/partials/userpages/driverFeatures.scss";

@import "../scss/partials/homepage/homepage.scss";

@import "../scss/partials/pages/team.scss";
@import "../scss/partials/pages/storyHeader.scss";

body {
  margin: 0;
  font-family: sans-serif;
  font-size: 1em;
}