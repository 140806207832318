.homepage-press {
  background-color: $yellow;
  text-align: center;
  padding: 20px 0;
  color: $navy-blue;
}
.homepage-press-banners {
  display:flex;
  flex-flow: row wrap;
  justify-content: space-between;

  div {
    margin: auto 0;
    padding: 4em 0;
    flex: 1;
    flex-basis: auto;
  }
}