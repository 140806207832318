.story-header {
  height: 683px;
  width: 100%;
  background-color: $background-blue;

  padding-left: 100px;
  padding-top: calc($nav-height * 2);
  padding-bottom: 50px;
}

.story-header-title {
  color: $busright-blue;
  font-size: 50px;
  font-weight: bold;
  font-family: $Nunito;
  margin: 0 0 0 0;
  width: 50%;
}

.story-header-description {
  color: $navy-blue;
  font-size: 22px;
  font-family: $Lato;
  width: 60%;
  padding-left: 5%;
}

.story-header-image {
  img {
    height: auto;
    max-width: 70%;
    max-height: 100%;

    position: absolute;
    background-size: contain;
    margin: 25px 25px 0 0;
  }
}

@media screen and (max-width: $tablet-width) {
  .story-header-description {
    color: $navy-blue;
    font-size: 22px;
    font-family: $Lato;
    width: 80%;
    padding-left: 0%;
  }
}

@media screen and (max-width: $mobile-width) {
  .story-header {
    height: 550px;
    width: 100%;

    padding-left: 10%;
    padding-top: 40%;
  }

  .story-header-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 0 0;
    width: 100%;
  }

  .story-header-description {
    font-size: 16px;
    width: 80%;
    padding-left: 0%;
  }
}