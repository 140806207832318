#driver-features {

  .feature-section {

    #feature-1 {
      .bg-svg {
        top: 140px;
        left: 30%;
      }
    }

    #feature-2 {
      .bg-svg {
        bottom: 50px;
        right: 100px;
      }
    }

    #feature-3 {
      .bg-svg {
        bottom: -30px;
        left: 30%;
      }
    }
  }
}
