.cta-footer {
  text-align: center;
  background-color: $navy-blue;
  color: $white;
  padding: 6em 0;

  .cta-shell {
    background-color: $red;
    border-radius: 30vw;
    padding: 1.5em 2em;

    @media screen and (min-width: $screen-width-sm) {
      padding: 1.5em 4em;
    }
  }
}