.main-title, .feature-section {
    color: $navy-blue;
}

.feature-description {
    max-width: 80vw;
    margin: auto;
    @media screen and (min-width:$screen-width-md) {
        max-width: 500px;
    }
}

.main-title {
    margin-bottom: 8em;
    text-align: center;
    position: relative;

    h4 {
        max-width: 800px;
        margin: auto;
    }
}

.feature-section {
    position: relative;
    margin: 4em 0;

    .feature-row {
        position: relative;

        margin-bottom: 12em;
        &:last-of-type {
            margin-bottom: 8em;
        }
        @media screen and (max-width:$screen-width-lg) {
            margin-bottom: 6em;
        }

        &:nth-of-type(odd) {

            @media screen and (min-width:$screen-width-lg) {
                >:first-child {
                    order: 2;
                    -ms-grid-column: 7;
                }
                >:nth-child(2) {
                    -ms-grid-column: 1;
                }
            }

            .feature-description {

                @media screen and (min-width:$screen-width-lg) {
                    padding-right: 125px;
                    padding-left: 0;
                }
            }
        }

        .feature-description {
            @media screen and (min-width:$screen-width-lg) {
                padding-left: 125px;
            }
        }

        .feature-testimonial {
            color: #666666;
            width: 75%;
            hr {
                margin: 2em 0;
                width: 60%;
                border: none;
                background-color: $light-blue;
                height: 5px;
            }
        }

        .feature-headline {
            color: $busright-blue;
            font-family: $Nunito;
            font-size: 40px;
            font-weight: bold;
            margin: 0px;
        }

        .feature-info-text {
            height: 100px;
            color: $dark-gray;
            font-family: $Lato;
            font-size: 22px;
            margin: 0px;
        }

        .feature-button {
            height: 40px;
            width: 135px;
            background-color: $bright-blue;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 28px 8px 0 0;

            p {
                color: $white;
                font-family: $Lato;
                font-weight: bold;
                font-size: 14px;
            }

            a:link {
                text-decoration: none;
            }
        }
        
        .feature-buttons {
            display: flex;
        }
    }
}