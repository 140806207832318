#hero {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  overflow-x: hidden;
  background-color: $navy-blue;

  color: $white;

  padding-top: $nav-height;

  @media screen and (min-width: $screen-width-lg) {
    padding-top: calc($nav-height + 6em);
  }

  @media screen and (min-width: $screen-width-lg) {
    .hero-img {
      margin-right: 1rem;
      margin-top: 2rem;
    }
  }
}

.hero-learn-more {
  width: 250px;
  color: $navy-blue;
  font-family: $Lato;
  font-size: 22px;
  font-weight: bold;

  margin: 54px 0 48px 0;
}

.hero-text {
  max-width: 500px;
  margin: auto;
  padding-bottom: 4em !important;

  @media screen and (min-width: $screen-width-lg) {
    padding-left: 100px;
    padding-right: 75px;
  }
}

.wave {
  display: flex;
  margin-top: -1px;
  overflow: hidden;
}
