.info-footer {
  background-color: $navy-blue;
  color: $white;

  margin-top: -1px;
  padding: 28px 0 4em 0;
}

.info-footer-content {
  font-family: $Lato;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;

  @media screen and (min-width: $screen-width-lg) {
    justify-content: space-between;
    
  }

  > * {
    flex: 1;
  }

  .info-footer-content-left, .info-footer-content-right {
    flex: 2;
    min-width: 300px;
    max-width: 400px;
    padding-bottom: 4em;
  }

  @media screen and (min-width: $screen-width-md) {
    .info-footer-content-left {
      padding-right: 100px;
    }
  }

}

.info-footer-content-left-logo {
  display: flex;
  margin-bottom: auto;

  .info-footer-tagline {
    border-left: 2px solid $white;
    margin-left: 20px;
    padding-left: 20px;
  }

  .footer-logo {
    margin: auto 0;
    img {
      height: 25px;
      width: 130px;
    }
  }
}

.info-footer-content-left-form {

 .info-footer-newsletter-form {
    width: 100%;
    display: flex;

    input[type="text"] {
      flex: 1;
      padding: 0px;
      padding-right: 50px;
      padding-left: 25px;
    }
    
    input[type="submit"] {
        margin: 0px;
        margin-left: -50px;
        border: 0;
        -webkit-appearance: none;
    }
 }
}

.info-footer-content, .info-footer-content-right, .info-footer-content-right-links {
  display: flex;
}

.info-footer-content-right {
  flex-direction: column;

  a {
    text-decoration: none;
    color: $white;
  
    p {
      margin: 0 0 0 0;
    }
  
    &:hover {
      text-decoration: underline;
    }
  }
}

.info-footer-content-right-links, .info-footer-content-right-info {
  flex-direction: row;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.info-footer-links-left {
  margin-right: 50px;
}

.info-footer-links-bottom {
  bottom: 0;
}

.info-footer-links {
  height: 100%;
}

.info-footer-info {
  width: 160px;
}


.info-footer-copyright {
  display: flex;
  font-family: $Lato;
  font-size: 14px;
}

.info-footer-info-address {
  margin: 0 0 0 0;
}

.info-footer-info-email {
  margin: 48px 0 0 0;
  text-decoration: none;
  font-weight: bold;
  color: $white;
  
  &:hover {
    text-decoration: underline;
  }
}


.info-footer-links {
  text-transform: uppercase;
}

.info-footer-link {
  line-height: 175%;
}


.info-footer-copyright-scout {
  text-decoration: none;
  font-weight: bold;
  color: $white;

  p {
    margin: 0 0 0 0;
  }

}

.busright-team-image {
  img {
    width: 25px;
  }
  margin: 0 0 0 750px;
}