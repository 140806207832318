#parent-features {

  .feature-section {

    .bg-svg {
      right: -20px;
      bottom: -40px;
    }

    #feature-1 {
      .bg-svg {
        top: -30px;
        left: 40px;
      }
    }
  }
}
